
import { LogisticsServiceProviderOption } from "@/core/directive/interface/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-logistics-service-provider",
  props: {
    LogisticsServiceProviderOption: {
      type: Object as () => LogisticsServiceProviderOption,
    },
  },
  setup() {
    return {};
  },
});
