
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType, NumberOrString } from "@/core/directive/type/common";
import { ApiLogisticsServiceProvider, ApiOrderRequest } from "@/core/api";
import { LogisticsServiceProviderOption } from "@/core/directive/interface/common";
import JwtService from "@/core/services/JwtService";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { OrderRequestItemTable } from "@/core/directive/interface/orderRequest";
import { ShipmentStatus } from "@/core/directive/type/shipment";
import {
  OrderRequestProgress,
  OrderRequestType,
} from "@/core/directive/type/orderRequest";
import CommonLogisticsServiceProviderOption from "@/components/layout/CommonLogisticsServiceProviderOption.vue";

export default defineComponent({
  name: "order-request-update-invoice-modal",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: OrderRequestProgress.New,
    },
    type: {
      type: Number,
      default: OrderRequestType.MerchantOrder,
    },
  },
  components: { CommonLogisticsServiceProviderOption },
  emits: ["update-list", "reset-form", "add-data"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateShipmentRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      shipping_date: "",
      carrier: "" as NumberOrString,
      tracking_no: "",
      pick_up_by: "",
      tracking_nos: "",
      shipment_items: [] as OrderRequestItemTable[],
      status: ShipmentStatus.Created,
    });

    const rules = ref({
      shipping_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pick_up_by: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      carrier: [] as LogisticsServiceProviderOption[],
    });

    watch(
      () => formData.value.tracking_no,
      (newVal) => {
        formData.value.tracking_no = newVal.split(" ").join("");
      }
    );

    const carrierChange = () => {
      if (formData.value.carrier === "") {
        formData.value.pick_up_by = "";
        formData.value.tracking_no = "";
      } else if (formData.value.carrier === -1) {
        formData.value.tracking_no = "";
      } else {
        formData.value.pick_up_by = "";
      }
    };

    const getName = computed(() => {
      return (row) => {
        if (!row) return "";
        return JwtService.getLang() === "zh_CN" ? row.name : row.name_en;
      };
    });

    const getShipmentItems = computed(() => {
      let arr: any[] = [];
      formData.value.shipment_items.map((item) => {
        if (item.shipped_qty >= 0 && typeof item.shipped_qty === "number") {
          arr.push({
            request_items_id: item.id,
            shipping_qty: item.shipped_qty,
          });
        }
      });
      return arr;
    });

    const getShowInfo = async () => {
      if (props.id === 0) return false;
      loading.value = true;
      const { data } = await ApiOrderRequest.getOrderRequestV2ShipmentInfo({
        request_shipment_id: props.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value.shipment_items = data.data.relation_shipment_item;
        formData.value.carrier = data.data.service_provider;
        formData.value.shipping_date = data.data.shipping_date;
        formData.value.tracking_no = data.data.tracking_no;
        formData.value.pick_up_by = data.data.pick_up_by;
        formData.value.tracking_nos = data.data.tracking_no;
        formData.value.status = Number(data.data.status);
      } else {
        showServerErrorMsg(data);
      }
    };

    const getOrderRequestItemV2Info = async () => {
      if (props.id !== 0) return false;
      loading.value = true;
      formData.value.tracking_no = "";
      formData.value.tracking_nos = "";
      const { data } = await ApiOrderRequest.getOrderRequestItemV2Info({
        merchant_request_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        const arr: OrderRequestItemTable[] = [];
        data.data.forEach((item) => {
          let count = 0;
          if (props.id === 0) {
            count = item.confirmed_qty - item.request_shipment_qty;
            if (props.type === OrderRequestType.DoubleSaleOrder) {
              item.shipped_qty = count;
            } else {
              item.shipped_qty = "";
            }
            // item.shipped_qty = item.confirmed_qty - item.request_shipment_qty;
            // item.shipped_qty = item.confirmed_qty - item.shipped_qty;
          }
          item.max_qty = count > 0 ? count : 0;
          if (count !== 0) {
            arr.push(item);
          }
        });
        formData.value.shipment_items = arr;
      }
    };

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("orderRequest.pickUp"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);

        options.value.carrier = shipmentProviderOptionsData;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([
        getShowInfo(),
        getOrderRequestItemV2Info(),
        getLogisticsServiceProviderData(),
      ])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          if (
            (props.id === 0 && formData.value.tracking_no != "") ||
            props.id !== 0
          ) {
            checkShipment();
          } else {
            if (props.id === 0) {
              Swal.fire({
                title: t("orderRequest.createShipmentTip"),
                icon: "question",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t("orderRequest.okConfirm"),
                cancelButtonText: t("orderRequest.nopeCancelIt"),
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-danger",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  createShipment();
                }
              });
            } else {
              Swal.fire({
                title: t("orderRequest.updatedShipmentTip"),
                icon: "question",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t("orderRequest.okConfirm"),
                cancelButtonText: t("orderRequest.nopeCancelIt"),
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-danger",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  updateShipment();
                }
              });
            }
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateShipment = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiOrderRequest.updateOrderRequestV2Shipment({
        request_shipment_id: props.id,
        shipping_date: formData.value.shipping_date,
        service_provider: formData.value.carrier,
        tracking_no: formData.value.tracking_no,
        pick_up_by: formData.value.pick_up_by,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          hideModal(updateShipmentRef.value);
          emit("update-list");
        });
      } else if (data.sub_code === "40002.STOCK_STATUS_40") {
        Swal.fire({
          title: t("orderRequest.packageMatched"),
          icon: "error",
        });
      } else if (data.sub_code === "40002.STOCK_STATUS_20") {
        Swal.fire({
          title: t("orderRequest.packageChecked"),
          icon: "error",
        });
      } else if (data.sub_code === "40002.STOCK_STATUS_80") {
        Swal.fire({
          title: t("orderRequest.packageRejected"),
          icon: "error",
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const checkShipment = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiOrderRequest.checkOrderRequestV2Shipment({
        service_provider: formData.value.carrier,
        tracking_no: formData.value.tracking_no,
        request_shipment_id: props.id,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code === 40002) {
        if (data.sub_code === "tracking_no_exists") {
          Swal.fire({
            title: t("orderRequest.checkTip"),
            icon: "question",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t("orderRequest.okConfirm"),
            cancelButtonText: t("orderRequest.nopeCancelIt"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              if (props.id === 0) {
                createShipment();
              } else {
                updateShipment();
              }
            }
          });
        } else if (data.sub_code === "exists_without_inspection") {
          Swal.fire({
            title: t("orderRequest.checkTipSame"),
            icon: "question",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t("orderRequest.okConfirm"),
            cancelButtonText: t("orderRequest.nopeCancelIt"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              if (props.id === 0) {
                createShipment();
              } else {
                updateShipment();
              }
            }
          });
        } else if (data.sub_code === "exists_with_inspection") {
          Swal.fire({
            title: t("orderRequest.packageChecked"),
            icon: "error",
            confirmButtonText: t("common.okGotIt"),
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        }
      }
      if (data.code == 0) {
        Swal.fire({
          title: t("orderRequest.createShipmentTip"),
          icon: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t("orderRequest.okConfirm"),
          cancelButtonText: t("orderRequest.nopeCancelIt"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (props.id === 0) {
              createShipment();
            } else {
              updateShipment();
            }
          }
        });
      }
    };

    const showTips = (
      { showButton = true, icon = "error", title = "" },
      callback
    ) => {
      if (showButton) {
        Swal.fire({
          title: title,
          icon: icon,
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t("orderRequest.okConfirm"),
          cancelButtonText: t("orderRequest.nopeCancelIt"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            callback();
          }
        });
      }
    };

    const createShipment = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiOrderRequest.createOrderRequestV2Shipment({
        merchant_request_id: route.params.id,
        shipping_date: formData.value.shipping_date,
        service_provider: formData.value.carrier,
        tracking_no: formData.value.tracking_no,
        pick_up_by: formData.value.pick_up_by,
        items: getShipmentItems.value,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          hideModal(updateShipmentRef.value);
          emit("update-list");
          emit("add-data");
        });
      } else if (data.sub_code === "40002.STOCK_STATUS_40") {
        Swal.fire({
          title: t("orderRequest.packageMatched"),
          icon: "error",
        });
      } else if (data.sub_code === "40002.STOCK_STATUS_20") {
        Swal.fire({
          title: t("orderRequest.packageChecked"),
          icon: "error",
        });
      } else if (data.sub_code === "40002.STOCK_STATUS_80") {
        Swal.fire({
          title: t("orderRequest.packageRejected"),
          icon: "error",
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(updateShipmentRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updateShipmentRef.value, () => {
        init();
      });
      modalHideListener(updateShipmentRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      OrderRequestProgress,
      ShipmentStatus,
      formRef,
      submitButtonRef,
      submitButton,
      updateShipmentRef,
      props,
      loading,
      formData,
      rules,
      options,
      getShipmentItems,
      carrierChange,
      getName,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
