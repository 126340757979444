
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  useAttrs,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import UpdateShipment from "@/views/merchant-order/order-request/UpdateShipment.vue";
import { useI18n } from "vue-i18n";
import {
  commonExportFile,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import JwtService from "@/core/services/JwtService";
import { previewOrderImages } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import _ from "lodash";
import {
  ApiDocumentsInvoice,
  ApiInspection,
  ApiOrderRequest,
} from "@/core/api";
import {
  OrderRequestOverview,
  orderRequestOverviewData,
  OrderRequestOverviewShipment,
} from "@/core/directive/interface/orderRequest";
import { getShipmentStatusMap } from "@/core/directive/function/shipment";
import { OrderRequestProgress } from "@/core/directive/type/orderRequest";
import { MenuComponent } from "@/assets/ts/components";
import PrintPreview from "@/components/layout/PrintPreview.vue";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "merchant-order-order-request-shipments",
  components: { UpdateShipment, PrintPreview, PermissionCommon },
  setup(props, context) {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const printPreviewRef = ref();
    const OrderRequestOverviewData = ref(
      Object.assign({}, orderRequestOverviewData)
    );
    const formData = ref({
      shipment_items: [] as OrderRequestOverviewShipment[],
    });
    const search = ref("");
    const shipmentId = ref(0);

    const options = ref({
      shipmentLoading: false,
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      printUrl: ApiInspection.printedMerchantSheet(),
    });

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        if (newValue === false) {
          const data = attrs.detailData as OrderRequestOverview;
          OrderRequestOverviewData.value = data;
          getFormInfo();
        }
      }
    );

    watch(
      () => route.params.id,
      (newValue) => {
        refreshDetail();
      }
    );

    const fileExport = async (item) => {
      if (item.downloadDisabled) return;
      item.downloadDisabled = true;
      const data = await ApiDocumentsInvoice.downloadPackingList({
        id: item.relation_shipment.id,
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    const isCanPrint = computed(() => {
      return (
        OrderRequestOverviewData.value.order_progress >=
        OrderRequestProgress.Confirmed
      );
      // return checkPermissionFlag(
      //   { auth: ["export"] },
      //   formData.value.order_progress >= OrderRequestProgress.Confirmed
      // );
    });

    const getPrintedSheet = async (item) => {
      const request_items_ids = item.relation_shipment_item.map((item) => {
        return item.request_items_id;
      });
      const params = {
        request_items_id: request_items_ids,
      };
      printPreviewRef.value?.modalShow(params);
    };

    const refreshDetail = () => {
      context.emit("getFormData");
    };

    const refreshListAndDetail = () => {
      refreshDetail();
      getFormInfo();
    };

    const editShipment = (id) => {
      shipmentId.value = id;
    };

    const handleReset = () => {
      shipmentId.value = 0;
    };

    const getShipmentList = async () => {
      options.value.shipmentLoading = true;
      const { data } = await ApiOrderRequest.getOrderRequestV2ShipmentList({
        merchant_request_id: route.params.id,
      });
      options.value.shipmentLoading = false;
      if (data.code === 0) {
        formData.value.shipment_items = data.data;
      }
    };

    const debounceSearch = _.debounce(getShipmentList, 1000);

    const searchShipmentItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      getShipmentList();
    };

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const getName = computed(() => {
      return (row) => {
        return JwtService.getLang() === "zh_CN" ? row.name : row.name_en;
      };
    });

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShipmentList()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        loading.value = false;
        const data = attrs.detailData as OrderRequestOverview;
        OrderRequestOverviewData.value = data;
        getFormInfo();
      }
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      OrderRequestProgress,
      getShipmentStatusMap,
      loading,
      OrderRequestOverviewData,
      formData,
      search,
      shipmentId,
      options,
      printPreviewRef,
      isCanPrint,
      fileExport,
      getPrintedSheet,
      refreshDetail,
      refreshListAndDetail,
      editShipment,
      handleReset,
      previewImages,
      getName,
      searchShipmentItems,
      removeSearch,
      getFormInfo,
    };
  },
});
