import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "flex-fill" }
const _hoisted_3 = {
  key: 0,
  class: "w-100px text-gray-400 text-end"
}
const _hoisted_4 = {
  key: 1,
  class: "w-100px text-gray-400 text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.LogisticsServiceProviderOption?.label), 1),
    (_ctx.LogisticsServiceProviderOption?.short_key)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.LogisticsServiceProviderOption.short_key), 1))
      : _createCommentVNode("", true),
    (_ctx.LogisticsServiceProviderOption?.short_name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.LogisticsServiceProviderOption.short_name), 1))
      : _createCommentVNode("", true)
  ]))
}